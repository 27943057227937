import clsx from "clsx";
import Link from "next/link";
import classes from "./Error404.module.scss";
import ErrorSvg from "./ErrorSvg";

export default function Error404() {
  return (
    <section className="no-padding py-8">
      <div className="container">
        <div className={clsx(classes.root)}>
          <ErrorSvg />
          <div>
            <h2 className={clsx(classes.title)}>
              Oups, tu as fait sauter l&apos;internet.
            </h2>
            <p className={clsx(classes.description)}>
              La page que vous recherchez n&apos;est plus là ou n&apos;a jamais
              existé (dommage). Prenons{" "}
              <Link href="/contactez-un-expert/">
                <a className="underline decoration-gray-300">
                  rendez-vous avec l&apos;un de nos experts
                </a>
              </Link>{" "}
              ou essayons de{" "}
              <Link href="/">
                <a className="underline decoration-gray-300">
                  rentrer à la maison
                </a>
              </Link>
              .
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
