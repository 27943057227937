import classes from "./ErrorSvg.module.scss";

function ErrorSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1871.9 1589.8"
      width="1871.9"
      xmlSpace="preserve"
      className={classes.main}
    >
      <g id="background">
        <g id="blob-left">
          <g>
            <path
              fill="#F0F2F5"
              d="M46.5,875.4c0,0,98.4-139.3-35.7-247.3c0,0,172.3,45.6,256.1-164.8c0,0,80.5,108.4,187.5,17.6
				c0,0,8.6,107,155.7,109.2c0,0-65.9,138.4,112.4,213.5c0,0-158.9,26.5-137.8,219.4c0,0-182.1-75.7-290.2,68.6
				c0,0-43.8-81.6-151.3-65.4C143.1,1026.2,160.8,914.9,46.5,875.4z"
            />
          </g>
        </g>
        <g id="blob-right">
          <g>
            <path
              fill="#F0F2F5"
              d="M1024.2,1109.6c0,0,119.2-168.8-43.3-299.7c0,0,208.8,55.3,310.4-199.8c0,0,97.5,131.3,227.2,21.3
				c0,0,10.5,129.7,188.6,132.3c0,0-79.9,167.7,136.2,258.7c0,0-192.6,32.1-167,265.9c0,0-220.7-91.7-351.7,83.2
				c0,0-53.1-98.9-183.4-79.3C1141.2,1292.3,1162.7,1157.5,1024.2,1109.6z"
            />
          </g>
        </g>
        <g id="bolb-middle">
          <g>
            <path
              fill="#F0F2F5"
              d="M883,304.6c0,0,66.4-94-24.1-166.9c0,0,116.3,30.8,172.8-111.2c0,0,54.3,73.1,126.5,11.8
				c0,0,5.8,72.2,105,73.7c0,0-44.5,93.4,75.9,144.1c0,0-107.2,17.9-93,148.1c0,0-122.9-51.1-195.9,46.3c0,0-29.5-55.1-102.1-44.1
				C948.2,406.3,960.1,331.2,883,304.6z"
            />
          </g>
        </g>
        <g id="exclamation-top">
          <g>
            <path
              fill="#F0F2F5"
              d="M659.9,261.4c4-1.1,7.1-3.4,9.2-7.1c2.1-3.7,2.5-7.6,1.4-11.8c-1.1-4.2-3.5-7.4-7.1-9.5
				c-3.6-2.2-7.4-2.7-11.4-1.6c-4.2,1.1-7.4,3.5-9.5,7.2c-2.1,3.7-2.7,7.6-1.6,11.8c1.1,4.2,3.5,7.4,7.2,9.5
				C651.7,262,655.7,262.5,659.9,261.4z M602.2,94.4l34.6,120.5l18.4-4.9L625.9,88.2L602.2,94.4z"
            />
          </g>
        </g>
        <g id="exclamation-left">
          <g>
            <path
              fill="#F0F2F5"
              d="M233.6,1215.1c1.9-4.2,2.1-8.5,0.4-12.8c-1.7-4.3-4.7-7.5-9-9.6c-4.4-2-8.7-2.3-13.1-0.7
				c-4.4,1.5-7.6,4.4-9.5,8.6c-2,4.4-2.2,8.8-0.6,13.2c1.6,4.4,4.6,7.7,9,9.7c4.4,2,8.8,2.2,13.2,0.6
				C228.3,1222.5,231.5,1219.5,233.6,1215.1z M50.4,1146.2l127.1,55.7l8.9-19.1l-124.6-61.3L50.4,1146.2z"
            />
          </g>
        </g>
        <g id="exclamation-right">
          <g>
            <path
              fill="#F0F2F5"
              d="M1365.4,508.2c4.3,3.4,9.1,4.7,14.5,4c5.4-0.7,9.9-3.3,13.3-7.7c3.5-4.4,5-9.4,4.4-14.8
				c-0.6-5.4-3-9.8-7.2-13.2c-4.4-3.5-9.4-4.9-14.9-4.3c-5.5,0.6-10,3.2-13.5,7.6c-3.5,4.4-4.9,9.4-4.3,14.9
				C1358.4,500.2,1360.9,504.7,1365.4,508.2z M1493.6,317.8l-98.1,130l19.4,15.3l103.7-125.6L1493.6,317.8z"
            />
          </g>
        </g>
      </g>
      <g id="Layer_4">
        <g>
          <path
            fill="#FFC89F"
            d="M360.9,1291.1c0,0-14.3,70.1-20.2,149.4H528l7.7-84.9C535.7,1355.5,497.3,1290.5,360.9,1291.1z"
          />
        </g>
        <g id="tshirt">
          <g>
            <path
              fill="#FFA400"
              d="M1337.7,1163.2C1337.7,1163.2,1337.7,1163.2,1337.7,1163.2c-1.1,1.1-71.3,70.8-75.4,277.2H534.7
				c0,0,5.3-44.3-15.6-71.2c0,0-102.5-10.8-160.9-81.4c0,0-8.6-196.2,98.7-303.8c12-12.1,28.2-19.1,45.3-19.5c0,0,28.4-14,70.9-31.5
				c42.5-17.4,99-38.3,155.2-51.9c0,0,21.4-57.2,68.7-4.3c101.1,113.3,226.6-82.9,275-8c21.4,21.2,57.8-28.6,75.7,4.9
				c0,0,39.2-4.5,49.9,6.7c0,0,47.9-8.4,79.8,9.2c0,0,86.5-7.2,149.5,18.9C1427,908.7,1556.5,1174.9,1337.7,1163.2z"
            />
          </g>
          <g>
            <path
              fill="#FF9A6C"
              d="M570.6,1438.5c-2.7-7.2-4.9-14.6-6.4-22.2c-0.4-1.9-0.8-3.8-1-5.7c-0.3-1.9-0.5-3.9-0.8-5.7
				c-0.3-1.8-0.8-3.7-1.4-5.4c-0.3-0.9-0.7-1.7-1-2.6l-1.2-2.7c-3-7.1-5.6-14.4-7.7-21.8c-2-7.4-3.4-15.1-3.2-22.9
				c0.3,3.8,0.9,7.6,1.7,11.3c0.8,3.7,1.8,7.4,3,11.1c2.3,7.3,5,14.5,8,21.5l1.1,2.6c0.4,0.9,0.8,1.8,1.1,2.8
				c0.6,1.9,1.1,3.8,1.4,5.7c0.2,1,0.3,2,0.4,2.9c0.1,0.9,0.2,1.9,0.3,2.8c0.3,1.9,0.5,3.8,0.8,5.7c0.6,3.8,1.4,7.5,2.2,11.3
				C568.7,1431.1,569.6,1434.8,570.6,1438.5z"
            />
          </g>
          <g opacity="0.4">
            <path
              fill="#A65B3C"
              d="M1337.7,1163.2c-1.1,1.1-71.3,70.7-75.4,277.2h-29.9c2.4-79-16.1-189.4-16.1-189.4s5.6,14.2,9.3,21.5
				c31.6,62.6,23.4,5.5,20.7-7.2c-18.7-88.2-17.8-156.9-17.8-156.9c12.2,56,43.6,72,43.6,72c-57.3-81.8-18.9-166.7-16.7-171.4
				c-1.5,3.6-22.2,55.6-1.9,116c16,47.6,42.2,46.5,42.2,46.5c-5-8.4-7.3-15.3-7.3-15.3C1314.2,1169.4,1337.1,1163.4,1337.7,1163.2z"
            />
          </g>
          <g opacity="0.4">
            <path
              fill="#A65B3C"
              d="M872.2,1156.3c0,0,32.6-24.1,104.4-22.4l1.8-24.3l7.7-1.1v-23.3c0,0,36.4,2.5,72.3,39.3l11.9-18.3
				c0,0-27.6-22.2-84.2-27.4v29.7C986.1,1108.5,920.5,1112.1,872.2,1156.3z"
            />
          </g>
          <g>
            <g opacity="0.4">
              <path
                fill="#A65B3C"
                d="M776.8,1084.1c0,0,1.5-27.5-15.6-36.5c-30-15.9-65.4,0-65.4,0c-8.1-87.1,23.1-149.6,30.8-163.5
					c-51.3,89.6-35.7,171.4-35.7,171.4c45.3-5.6,74.3,33.6,74.3,33.6c70.4,14.1,100.4,67.2,100.4,67.2
					C831.6,1095,776.8,1084.1,776.8,1084.1z"
              />
            </g>
            <g opacity="0.4">
              <path
                fill="#A65B3C"
                d="M726.6,884.2c0.6-1,1.1-1.9,1.7-2.9C728.3,881.2,727.7,882.2,726.6,884.2z"
              />
            </g>
          </g>
          <g opacity="0.4">
            <path
              fill="#A65B3C"
              d="M865.6,1164.1c0,0,4.8,61.2-3.4,143.3L865.6,1164.1z"
            />
          </g>
          <g opacity="0.4">
            <path
              fill="#A65B3C"
              d="M1211.1,1129.7c0,0-7.4-80.2,52.5-151.2C1263.6,978.5,1194.4,1009.1,1211.1,1129.7z"
            />
          </g>
          <g opacity="0.4">
            <path
              fill="#A65B3C"
              d="M904.5,1440.4c0,0,137.4-139.4,190.6-327.5c0,0-61.6,200.6-230.1,327.5L904.5,1440.4z"
            />
          </g>
          <g opacity="0.4">
            <path
              fill="#A65B3C"
              d="M1029.2,1440.4c0,0,37.6-81.8,65.9-197.5c0,0-46.5,125.3-102.1,197.5H1029.2z"
            />
          </g>
          <g opacity="0.4">
            <path
              fill="#A65B3C"
              d="M623.7,1307.5l37.6,133h-29C636.4,1379.1,623.7,1307.5,623.7,1307.5z"
            />
          </g>
          <g opacity="0.4">
            <path
              fill="#A65B3C"
              d="M1118.1,990.9c0,0,7.6-94.1,79.5-110.3C1197.6,880.6,1121.3,867.2,1118.1,990.9z"
            />
          </g>
          <g opacity="0.4">
            <path
              fill="#A65B3C"
              d="M400.8,1246.3c0,0,12.8,64.1,89.5,65.3C490.3,1311.6,443.1,1315.6,400.8,1246.3z"
            />
          </g>
          <g opacity="0.4">
            <path
              fill="#A65B3C"
              d="M467.5,1274.1c0,0,43.4,49.3,63.5,110.2C531,1384.3,493.4,1309.8,467.5,1274.1z"
            />
          </g>
          <g opacity="0.4">
            <path
              fill="#A65B3C"
              d="M433.8,1195.8c0,0-2.1,33.2,29.6,74.5C463.4,1270.2,425.2,1242.9,433.8,1195.8z"
            />
          </g>
          <g>
            <path
              fill="#A65B3C"
              d="M904.5,1440.4c5.5-5.7,10.8-11.7,16.1-17.6c5.3-6,10.3-12.1,15.4-18.2c2.5-3.1,5-6.1,7.5-9.3
				c2.5-3.1,4.9-6.2,7.4-9.4c4.9-6.3,9.7-12.6,14.4-19c4.8-6.4,9.5-12.8,14-19.3c2.3-3.2,4.6-6.5,6.8-9.8c2.3-3.3,4.5-6.6,6.6-9.9
				c8.8-13.3,17-26.9,24.8-40.8c15.7-27.7,29.8-56.2,42.8-85.3c12.8-29.1,24.4-58.7,34.7-88.9l0.2,0.1c-9.1,30.5-20.2,60.5-33,89.7
				c-6.5,14.6-13.3,29-20.6,43.1c-3.6,7.1-7.5,14.1-11.2,21.1c-3.9,6.9-7.8,13.9-11.9,20.7c-4,6.9-8.3,13.6-12.4,20.5
				c-4.2,6.7-8.5,13.5-12.8,20.2l-3.2,5l-3.3,5c-2.2,3.3-4.3,6.7-6.6,10c-4.4,6.6-9,13.2-13.6,19.6c-2.4,3.2-4.7,6.5-7.1,9.6
				c-2.4,3.2-4.8,6.4-7.3,9.5c-2.5,3.1-4.9,6.3-7.5,9.3c-2.5,3.1-5.1,6.2-7.6,9.2c-10.3,12.1-21,24.1-32.5,35L904.5,1440.4z"
            />
          </g>
          <g>
            <path
              fill="#A65B3C"
              d="M1029.2,1440.4c3.4-8,6.6-16.1,9.7-24.2c3.2-8.1,6.3-16.2,9.2-24.4c6-16.3,11.8-32.7,17.2-49.2
				c5.5-16.5,10.7-33,15.6-49.7c5-16.6,9.6-33.4,14.2-50.1c-1,4.2-1.9,8.5-2.9,12.7c-1,4.2-2,8.5-3.1,12.7
				c-2.2,8.4-4.3,16.8-6.7,25.2c-1.2,4.2-2.3,8.4-3.6,12.5l-3.8,12.5c-1.2,4.2-2.6,8.3-3.9,12.4c-1.3,4.1-2.6,8.3-4,12.4l-4.2,12.3
				l-4.3,12.3c-1.4,4.1-3,8.2-4.5,12.3c-0.8,2-1.5,4.1-2.3,6.1l-2.4,6.1c-1.6,4.1-3.2,8.1-4.8,12.1c-1.6,4-3.3,8-4.9,12.1
				C1036.4,1424.6,1033,1432.6,1029.2,1440.4z"
            />
          </g>
          <g>
            <path
              fill="#A65B3C"
              d="M661.3,1440.4c-4-10.8-7.7-21.7-11.2-32.7c-3.5-11-6.8-22-10-33.1c-3.2-11.1-6.1-22.2-8.9-33.4
				c-2.8-11.2-5.3-22.4-7.5-33.7c1.6,5.5,2.9,11.1,4.5,16.7l4.5,16.7c3.1,11.1,6.1,22.2,9.3,33.3c3.1,11.1,6.4,22.1,9.6,33.2
				L661.3,1440.4z"
            />
          </g>
          <g>
            <path
              fill="#A65B3C"
              d="M1272,1180.5c-9.4-11.8-16.4-25.3-21.8-39.4c-5.4-14.1-8.8-29-10.2-44c-0.4-3.8-0.5-7.5-0.7-11.3
				c0-1.9,0-3.8,0-5.7c0-0.9,0-1.9,0-2.8l0.1-2.8c0.3-7.5,1.1-15.1,2.5-22.5c0.7-3.7,1.4-7.4,2.4-11.1c0.5-1.8,0.9-3.7,1.4-5.5
				l1.6-5.4l0.8-2.7l1-2.7l1.9-5.3c1.3-3.5,2.9-7,4.3-10.4l-1.9,5.3l-1,2.6c-0.3,0.9-0.7,1.8-0.9,2.7l-1.7,5.4l-0.8,2.7l-0.7,2.7
				l-1.5,5.4c-0.5,1.8-0.8,3.7-1.3,5.5c-0.9,3.6-1.4,7.3-2.1,11c-0.2,0.9-0.3,1.8-0.4,2.8l-0.3,2.8c-0.2,1.9-0.5,3.7-0.7,5.6
				l-0.5,5.6c-0.2,1.9-0.2,3.7-0.3,5.6l-0.1,2.8c0,0.9,0,1.9,0,2.8c0,1.9,0,3.7,0.1,5.6c0.2,3.7,0.3,7.5,0.7,11.2
				c0.7,7.4,1.8,14.8,3.4,22.1c1.6,7.3,3.8,14.5,6.2,21.5c2.5,7,5.4,14,8.8,20.6C1263.8,1167.9,1267.6,1174.4,1272,1180.5z"
            />
          </g>
          <g>
            <path
              fill="#A65B3C"
              d="M1245.8,1261.8c-1.5-6.3-2.9-12.6-4.3-18.9c-1.3-6.3-2.5-12.6-3.7-19c-2.3-12.7-4.2-25.5-5.8-38.2
				c-1.5-12.8-2.7-25.7-3.4-38.5c-0.1-3.2-0.3-6.4-0.4-9.7c-0.1-3.2-0.2-6.4-0.2-9.7l0.1-9.7c0.1-3.2,0.2-6.4,0.3-9.7
				c0.1,3.2,0.2,6.4,0.2,9.7l0.4,9.6l0.5,9.6c0.2,3.2,0.4,6.4,0.7,9.6c1,12.8,2.2,25.6,3.8,38.4c1.5,12.8,3.4,25.5,5.3,38.2
				c1,6.4,2,12.7,3.1,19.1C1243.6,1249.1,1244.7,1255.5,1245.8,1261.8z"
            />
          </g>
          <g>
            <path
              fill="#A65B3C"
              d="M1211.1,1129.7c-1-13.6,0-27.4,1.9-40.9c0.9-6.8,2.3-13.5,3.8-20.2c1.6-6.7,3.4-13.3,5.6-19.8
				c2.2-6.5,4.7-12.9,7.5-19.1l2.2-4.7c0.7-1.6,1.5-3.1,2.3-4.6c0.8-1.5,1.5-3.1,2.4-4.6l2.5-4.5c0.8-1.5,1.8-2.9,2.7-4.4
				c0.9-1.4,1.8-2.9,2.7-4.3c1.9-2.8,3.8-5.7,5.9-8.4c4-5.5,8.5-10.7,13.1-15.8c-4.3,5.3-8.5,10.6-12.3,16.3c-2,2.8-3.8,5.7-5.6,8.5
				c-0.9,1.4-1.7,2.9-2.6,4.4c-0.8,1.5-1.7,2.9-2.5,4.4c-3.3,6-6.3,12.1-9,18.3c-2.7,6.2-5.1,12.6-7.3,19
				c-4.3,12.9-7.6,26.1-9.8,39.5c-1.1,6.7-2,13.5-2.5,20.3C1211.3,1116.1,1211,1122.9,1211.1,1129.7z"
            />
          </g>
          <g>
            <path
              fill="#A65B3C"
              d="M1118.1,990.9c0.4-6.1,1.4-12.1,2.5-18c1.2-6,2.7-11.9,4.5-17.7c3.6-11.6,8.4-22.9,14.8-33.3
				c3.2-5.2,6.8-10.1,10.9-14.8c4.1-4.6,8.6-8.7,13.5-12.4c4.9-3.7,10.3-6.6,15.9-9.1l4.3-1.7c1.4-0.5,2.9-0.9,4.4-1.3l2.2-0.6
				c0.7-0.2,1.5-0.3,2.2-0.5l4.5-0.9c-5.9,1.5-11.7,3.3-17.1,5.9l-1,0.5l-1,0.5l-2,1.1l-2,1.1c-0.7,0.3-1.3,0.8-1.9,1.2
				c-1.3,0.8-2.5,1.6-3.8,2.4l-3.6,2.7c-4.7,3.7-9.1,7.9-13,12.4c-3.9,4.6-7.4,9.4-10.6,14.6c-3.2,5.1-5.9,10.5-8.4,15.9
				c-2.5,5.5-4.7,11.1-6.6,16.8c-1.9,5.7-3.6,11.5-5,17.4C1120.1,978.9,1118.9,984.9,1118.1,990.9z"
            />
          </g>
          <g>
            <path
              fill="#A65B3C"
              d="M1188.2,916.6c5.5-5.9,12.1-10.6,19.1-14.6c7-3.9,14.6-7,22.3-9.2c7.8-2.2,15.8-3.6,23.8-4.1
				c8-0.5,16.1-0.2,24,1c-8-0.4-16-0.2-23.9,0.6c-1,0.1-2,0.2-3,0.3l-3,0.4c-2,0.3-3.9,0.6-5.9,1c-3.9,0.7-7.8,1.7-11.6,2.7
				c-3.8,1-7.6,2.4-11.3,3.8c-3.7,1.4-7.3,3.1-10.9,4.9c-3.5,1.8-7,3.8-10.3,6C1194.4,911.6,1191.1,914,1188.2,916.6z"
            />
          </g>
          <g>
            <path
              fill="#A65B3C"
              d="M1290.4,944c5.5-7.7,12.5-14.2,20.1-19.7c7.7-5.5,16.1-10.1,24.9-13.4c4.4-1.7,9-3.1,13.6-4.1
				c2.3-0.6,4.6-1,7-1.4c2.3-0.4,4.7-0.7,7-1c9.4-1,18.9-0.8,28.2,0.4c-9.4-0.5-18.8-0.1-28,1.1c-1.2,0.1-2.3,0.3-3.5,0.5
				c-1.1,0.2-2.3,0.4-3.4,0.6c-2.3,0.5-4.6,0.9-6.8,1.5c-2.3,0.5-4.5,1.2-6.7,1.9c-2.2,0.7-4.4,1.4-6.6,2.3
				c-4.4,1.6-8.6,3.5-12.8,5.7c-4.1,2.1-8.1,4.5-12,7.2c-3.9,2.6-7.6,5.5-11.1,8.5C1296.8,937.1,1293.4,940.4,1290.4,944z"
            />
          </g>
          <g>
            <path
              fill="#A65B3C"
              d="M1078.5,1112.9c2.4-10.1,4.5-20.2,6.3-30.4c1.9-10.2,3.4-20.4,4.7-30.7c2.6-20.5,4.4-41.2,4.5-61.9
				c0.1-2.6,0-5.2,0-7.8c0-2.6,0-5.2-0.1-7.8l-0.2-7.8l-0.4-7.7c-0.7-10.3-1.8-20.6-3.4-30.8l-0.3-1.9l-0.4-1.9l-0.7-3.8l-0.7-3.8
				c-0.3-1.3-0.6-2.5-0.9-3.8c-0.6-2.5-1.1-5-1.8-7.5l-2.1-7.5c-3-9.9-6.7-19.6-11.2-28.9c4.9,9.2,8.8,18.8,12.1,28.7l2.3,7.5
				c0.7,2.5,1.3,5,1.9,7.5c0.3,1.3,0.7,2.5,0.9,3.8l0.8,3.8l0.8,3.8l0.4,1.9l0.3,1.9c3.6,20.5,4.8,41.3,4.6,62.1
				c-0.1,10.4-0.7,20.8-1.5,31.1c-0.9,10.3-1.9,20.7-3.5,30.9C1088.1,1072.5,1084.1,1092.9,1078.5,1112.9z"
            />
          </g>
          <g>
            <path
              fill="#A65B3C"
              d="M986.1,1108.5c-0.2-5-0.3-9.9-0.4-14.9l-0.2-7.4l-0.1-7.4l0-0.8l0.8,0c4.2,0.3,8.4,0.7,12.5,1.3
				c4.1,0.6,8.3,1.3,12.4,2.1c8.2,1.6,16.3,3.8,24.3,6.4c4,1.4,7.9,2.9,11.7,4.5c3.8,1.7,7.6,3.5,11.3,5.6c3.6,2.1,7.2,4.3,10.6,6.8
				c3.4,2.5,6.6,5.2,9.5,8.2c-3.2-2.7-6.5-5.2-10-7.5c-3.5-2.2-7.1-4.3-10.8-6.2c-7.4-3.8-15.1-6.9-23-9.4
				c-7.9-2.6-15.9-4.7-24.1-6.4c-8.1-1.7-16.4-3-24.6-3.8l0.8-0.7l-0.1,7.4l-0.2,7.4C986.4,1098.6,986.3,1103.6,986.1,1108.5z"
            />
          </g>
          <g>
            <path
              fill="#A65B3C"
              d="M872.2,1156.3c7.5-7.4,15.8-13.9,24.7-19.5c8.9-5.6,18.3-10.4,28.1-14.3c9.8-3.9,19.9-6.9,30.1-9.2
				c10.2-2.3,20.6-3.9,31.1-4.8c-10.3,1.7-20.6,3.8-30.7,6.3c-10.1,2.6-20.1,5.7-29.7,9.6c-9.7,3.8-19.1,8.4-28,13.7
				C888.7,1143.5,880.2,1149.5,872.2,1156.3z"
            />
          </g>
          <g>
            <path
              fill="#A65B3C"
              d="M761.2,1047.6c3.2,1.4,5.7,4,7.9,6.7c2.1,2.8,3.7,5.9,5,9.2c1.2,3.3,2.1,6.7,2.6,10.1
				c0.6,3.4,0.9,6.9,1,10.4l-0.7-0.8c4.9,1.1,9.5,2.7,14.2,4.5c4.6,1.8,9.1,3.9,13.5,6.2c8.7,4.6,17,10.1,24.6,16.4
				c7.7,6.2,14.5,13.4,20.7,21.1c3,3.9,6,7.8,8.5,12c1.4,2,2.5,4.2,3.8,6.3c1.2,2.1,2.3,4.3,3.5,6.5c-1.3-2.1-2.5-4.2-3.8-6.3
				c-1.3-2-2.6-4.1-4.1-6.1l-2.1-3l-2.2-2.9c-1.4-2-3-3.8-4.6-5.7c-3.2-3.7-6.4-7.4-10-10.7c-0.9-0.9-1.7-1.7-2.6-2.5l-2.7-2.5
				c-1.8-1.6-3.7-3.2-5.6-4.7c-7.5-6.2-15.8-11.5-24.4-16.1c-4.3-2.3-8.7-4.4-13.2-6.2c-4.5-1.8-9.2-3.4-13.8-4.5l-0.7-0.2l0-0.7
				c-0.1-3.4-0.2-6.8-0.7-10.2c-0.5-3.4-1.2-6.7-2.3-10c-1.1-3.2-2.5-6.4-4.4-9.2c-1-1.4-2-2.7-3.3-4
				C764.1,1049.6,762.8,1048.4,761.2,1047.6z"
            />
          </g>
          <g>
            <path
              fill="#A65B3C"
              d="M696,1047.6c2.4-1.4,5.1-2.4,7.7-3.5c2.7-0.9,5.4-1.7,8.1-2.3c5.5-1.2,11.2-1.9,16.8-1.8
				c5.6,0.1,11.3,0.6,16.8,2c2.8,0.5,5.4,1.5,8.1,2.3c1.3,0.4,2.6,1,3.9,1.6c1.3,0.5,2.6,1.1,3.8,1.7c-1.4-0.4-2.7-0.8-4-1.2
				c-1.3-0.4-2.6-0.9-4-1.1c-2.7-0.6-5.4-1.4-8.1-1.8l-2-0.4c-0.7-0.1-1.4-0.3-2-0.3l-4.1-0.4c-1.4-0.2-2.8-0.2-4.1-0.3
				c-1.4-0.1-2.8-0.2-4.1-0.1c-1.4,0-2.8,0-4.1,0l-4.1,0.3c-2.8,0.2-5.5,0.6-8.2,1C706.6,1044.3,701.2,1045.6,696,1047.6z"
            />
          </g>
          <g>
            <path
              fill="#A65B3C"
              d="M862.2,1307.5c0.5-6,0.9-11.9,1.3-17.9c0.4-6,0.8-11.9,1.2-17.9c0.7-11.9,1.3-23.9,1.7-35.8
				c0.2-6,0.4-11.9,0.4-17.9c0.2-6,0.1-11.9,0.1-17.9c-0.1-11.9-0.5-23.9-1.3-35.8c1.6,11.9,2.5,23.8,2.9,35.8
				c0.4,12,0.2,24-0.1,35.9c-0.4,12-1.1,23.9-2.2,35.9C865.2,1283.7,864,1295.6,862.2,1307.5z"
            />
          </g>
          <g>
            <path
              fill="#A65B3C"
              d="M531,1384.3c-1.8-5-3.8-10-5.9-14.9l-3.2-7.3l-3.3-7.3c-4.5-9.7-9.4-19.1-14.7-28.4
				c-2.6-4.7-5.4-9.2-8.2-13.7c-1.4-2.3-2.9-4.5-4.3-6.7l-2.2-3.3l-2.3-3.3l-4.6-6.5l-4.9-6.3c-0.8-1.1-1.6-2.1-2.4-3.2l-2.6-3.1
				c-1.7-2-3.4-4.1-5.1-6.2c1.8,1.9,3.7,3.9,5.5,5.8l2.7,2.9c0.9,1,1.7,2,2.6,3.1l5.1,6.2c1.6,2.1,3.2,4.3,4.8,6.4l2.4,3.2l2.3,3.3
				c1.5,2.2,3,4.4,4.5,6.7c2.8,4.6,5.7,9.1,8.3,13.8c5.3,9.3,10.1,18.9,14.3,28.7C524.3,1364,528.1,1374,531,1384.3z"
            />
          </g>
          <g>
            <path
              fill="#A65B3C"
              d="M463.4,1270.2c-4.6-5.1-8.6-10.6-12.2-16.4c-3.6-5.8-6.8-11.9-9.5-18.2c-2.7-6.3-4.7-12.9-6.2-19.5
				c-1.5-6.7-1.9-13.6-1.7-20.4c0.2,1.7,0.4,3.4,0.5,5.1c0.1,0.8,0.1,1.7,0.3,2.5l0.4,2.5c0.3,1.7,0.5,3.3,0.9,5l1.2,4.9l0.3,1.2
				l0.4,1.2l0.7,2.4c0.5,1.6,0.9,3.2,1.5,4.8l1.7,4.8l1.9,4.7c2.6,6.2,5.7,12.3,9,18.2c1.7,2.9,3.4,5.9,5.2,8.7
				C459.5,1264.7,461.4,1267.5,463.4,1270.2z"
            />
          </g>
          <g>
            <path
              fill="#A65B3C"
              d="M490.3,1311.6c-10,0.7-20.2-0.5-30-3c-9.8-2.5-19.2-6.6-27.6-12.4c-4.2-2.9-8.1-6.2-11.6-9.8
				c-3.5-3.7-6.6-7.7-9.3-12c-5.3-8.6-8.9-18.2-10.9-28.1c2.8,9.6,6.8,18.9,12.2,27.2c2.8,4.1,5.9,8,9.4,11.5
				c3.5,3.5,7.3,6.7,11.4,9.5c4.1,2.9,8.4,5.2,12.9,7.3c4.5,2.1,9.2,3.8,14,5.2c4.8,1.4,9.7,2.5,14.6,3.2
				C480.3,1311.1,485.3,1311.5,490.3,1311.6z"
            />
          </g>
          <g>
            <path
              fill="#A65B3C"
              d="M465.8,1260.7c-4.9-23.9-7.5-48.3-8.8-72.7c-1.2-24.4-0.8-48.9,1.3-73.3c0.3-3,0.5-6.1,0.9-9.1l1.1-9.1
				c0.4-3,0.9-6.1,1.3-9.1c0.2-1.5,0.4-3,0.7-4.5l0.8-4.5l0.8-4.5c0.3-1.5,0.5-3,0.9-4.5l1.8-9c0.6-3,1.4-6,2.1-8.9l1.1-4.5l1.2-4.4
				c3.1-11.8,7.1-23.4,11.5-34.8l3.5-8.5c1.2-2.8,2.5-5.6,3.8-8.3l1.9-4.2c0.7-1.4,1.4-2.7,2.1-4.1l4.2-8.1
				c-10.9,21.8-19.5,44.8-25.5,68.4l-1.2,4.4l-1,4.5c-0.7,3-1.4,5.9-2,8.9l-1.8,9c-0.3,1.5-0.6,3-0.8,4.5l-0.8,4.5l-0.8,4.5
				c-0.3,1.5-0.4,3-0.7,4.5c-0.4,3-0.9,6-1.3,9l-1,9.1c-0.4,3-0.6,6.1-0.9,9.1c-2.3,24.3-2.6,48.7-1.8,73
				c0.4,12.2,1.3,24.4,2.5,36.5C462.2,1236.6,463.7,1248.7,465.8,1260.7z"
            />
          </g>
          <g>
            <path
              fill="#A65B3C"
              d="M728.3,881.2c-3.4,6.3-6.4,12.8-9.2,19.4c-2.8,6.6-5.3,13.3-7.6,20.1c-2.2,6.8-4.4,13.6-6.2,20.5
				c-1.9,6.9-3.4,13.9-4.7,20.9c-2.8,14-4.4,28.2-5.3,42.5c-0.3,7.1-0.6,14.3-0.4,21.5c0,3.6,0.2,7.2,0.3,10.7
				c0,1.8,0.2,3.6,0.3,5.4l0.3,5.4c-0.9-7.1-1.6-14.3-2-21.4c-0.4-7.2-0.3-14.4-0.2-21.6c0.5-14.4,2.2-28.7,4.9-42.8
				c2.8-14.1,6.5-28,11.4-41.6C715,906.8,720.8,893.5,728.3,881.2z"
            />
          </g>
          <g opacity="0.8">
            <ellipse
              transform="matrix(0.7071 -0.7071 0.7071 0.7071 -577.0288 978.4044)"
              fill="#FFFFFF"
              cx="892.5"
              cy="1185.7"
              rx="12"
              ry="12"
            />
          </g>
          <g opacity="0.8">
            <ellipse
              transform="matrix(0.1602 -0.9871 0.9871 0.1602 -490.3719 1935.8834)"
              fill="#FFFFFF"
              cx="892.5"
              cy="1256.1"
              rx="12"
              ry="12"
            />
          </g>
          <g>
            <g>
              <rect
                x="624.4"
                y="1167"
                transform="matrix(0.6349 -0.7726 0.7726 0.6349 -675.9048 914.7936)"
                width="11.1"
                height="11.1"
              />
              <polygon points="630.7,1180.3 649.9,1196 665.4,1194.5 637.7,1171.7 				" />
              <polygon points="660.4,1144 644.9,1145.6 629.1,1164.7 637.7,1171.7 				" />
            </g>
            <polygon
              fill="#FFFFFF"
              points="623.5,1187.7 635.4,1197.4 624.6,1198.5 			"
            />
          </g>
        </g>
        <g id="line">
          <rect x="169.7" y="1438.5" fill="#1B1A2E" width="1514.8" height="3" />
        </g>
        <g id="left-hand">
          <g>
            <g>
              <path
                fill="#FFC89F"
                d="M579.1,1438.5c0,0-5.6-17.2-7-31.3c-0.4-3.6-1.3-7.2-2.7-10.6c-4.3-9.7-12.8-30.9-13-47.1
					c-0.2-21.5-45.2,4.5-25.2,55.9c0,0-29.4-20-58.6,4.8c0,0-18.5-29.9-60.3-7.4c0,0-17.2-25.9-49.7-6.1c0,0-53-21.8-60.3,41.7h26.6
					l8.3,9.6c0,0-13.9,40.4,19.1,54.3c0,0-2.8,29.7,7.9,46.8c7.1,11.3,24.5,8.6,28-4.3c0-0.1,0.1-0.3,0.1-0.4
					c0,0,5.6,16.1,19.2,13.7c11.4-2,18.6-17,18.6-17s23.1,6.7,39.5-20.6c16.5-27.3,26.6-42.4,26.6-42.4l30.9-39.7H579.1z"
              />
            </g>
            <g>
              <path
                fill="#FF9A6C"
                d="M472.7,1410.3c2.2,5.5,2.4,11.6,1.7,17.4c-0.1,0.7-0.2,1.5-0.4,2.2l-0.4,2.2c-0.4,1.4-0.8,2.9-1.2,4.3
					c-0.5,1.4-1,2.8-1.6,4.1c-0.6,1.3-1.2,2.7-1.9,4c-0.7,1.3-1.5,2.5-2.3,3.8l-2.5,3.6c-1.7,2.4-3.3,4.7-4.7,7.3
					c-2.8,5.1-4.9,10.5-6.8,16c-1.8,5.5-3.5,11.1-5.1,16.7l-4.7,16.8c-1.6,5.6-3.3,11.2-5.2,16.7c-0.9,2.8-2,5.5-3.1,8.2
					c-0.6,1.4-1.2,2.7-1.9,4c-0.7,1.3-1.5,2.6-2.4,3.7l-0.2-0.1c1.5-2.5,2.5-5.2,3.5-7.9c1-2.7,1.7-5.5,2.6-8.3l4.6-16.8
					c1.6-5.6,3.2-11.2,5-16.8c1.7-5.6,3.7-11.1,5.9-16.5c1.1-2.7,2.3-5.4,3.4-8.1c1.2-2.7,2.5-5.3,3.8-7.9c1.3-2.6,2.8-5.1,4.4-7.6
					l2.2-3.7c0.7-1.3,1.3-2.6,2-3.8c2.5-5.2,4.6-10.6,5.5-16.2c0.5-2.8,0.7-5.7,0.8-8.6c0-2.9-0.3-5.8-1.1-8.6L472.7,1410.3z"
              />
            </g>
            <g>
              <path
                fill="#FF9A6C"
                d="M412.4,1402.9c1.1,1.1,1.9,2.4,2.7,3.8c0.4,0.7,0.7,1.4,1,2.1l0.8,2.2c1,3,1.5,6,1.8,9.2
					c0.4,6.2-0.6,12.5-2.4,18.4c-0.5,1.5-0.9,2.9-1.5,4.4l-1.8,4.3c-1.2,2.8-2.5,5.6-3.6,8.4c-2.3,5.7-4.2,11.5-6,17.3
					c-1.7,5.9-3.3,11.8-4.8,17.7c-1.4,5.9-2.8,11.9-3.8,17.9c-1.1,6-2,12-2.5,18.1c-0.5,6.1-0.7,12.2-0.2,18.3l-0.2,0
					c-1.2-6-1.4-12.2-1.2-18.4c0.2-6.2,1-12.3,2-18.4c1-6.1,2.5-12,4.1-18c1.6-5.9,3.5-11.8,5.6-17.6l3.2-8.6l1.6-4.3l1.6-4.3
					c1-2.9,2.2-5.7,3.2-8.6l1.5-4.3c0.5-1.4,0.8-2.9,1.2-4.4c1.5-5.9,2.5-11.8,2.4-17.9c-0.1-3-0.5-6-1.3-8.9
					c-0.8-2.9-1.9-5.8-3.7-8.2L412.4,1402.9z"
              />
            </g>
            <g>
              <path
                fill="#FF9A6C"
                d="M356.3,1502.3c0.6,0.2,1.2,0.4,1.8,0.5c0.6,0.2,1.2,0.3,1.8,0.4c1.2,0.1,2.3-0.3,3.2-1
					c0.9-0.7,1.5-1.7,1.7-2.8c0.1-0.5,0.1-1.2,0.1-1.8l0.1-1.9c0.4-5,0.9-10,1.7-14.9c0.9-4.9,2-9.8,3.1-14.7
					c0.5-2.4,1.1-4.9,1.7-7.3c0.3-1.2,0.7-2.4,1-3.6c0.4-1.2,0.7-2.4,1.1-3.6c0.4-1.2,0.9-2.4,1.3-3.5c0.4-1.2,0.7-2.3,0.9-3.5
					c0.2-1.2,0.4-2.4,0.4-3.6c0-1.2,0.1-2.5,0.1-3.7c0-2.5,0-4.9-0.2-7.4c-0.2-2.5-0.4-4.9-0.8-7.3c-0.7-4.9-1.9-9.6-3.9-14.1
					c-2-4.4-4.9-8.5-8.8-11.6l0.1-0.2c4.3,2.6,7.7,6.6,10.1,11.1c2.4,4.5,3.8,9.5,4.6,14.5c0.8,5,1,10.1,0.7,15.1
					c0,1.3-0.1,2.5-0.2,3.8c-0.1,1.3-0.3,2.6-0.6,3.8c-0.3,1.2-0.7,2.5-1.2,3.7c-0.5,1.2-1,2.3-1.5,3.4c-1.9,4.6-3.2,9.4-4.2,14.3
					c-1,4.9-1.5,9.8-2.1,14.8l-1.8,14.8l-0.2,1.9c-0.1,0.6-0.1,1.2-0.3,1.9c-0.3,1.4-1.2,2.6-2.3,3.4c-1.1,0.8-2.6,1.1-3.9,0.8
					c-0.7-0.1-1.3-0.4-1.8-0.6c-0.6-0.2-1.2-0.5-1.7-0.8L356.3,1502.3z"
              />
            </g>
            <g>
              <path
                fill="#FF9A6C"
                d="M528.8,1404.1c3.2,1.1,6.2,3.5,8,6.6c0.9,1.6,1.5,3.3,1.7,5.1c0.2,1.8,0.1,3.6-0.2,5.4
					c-0.4,1.8-1,3.4-1.7,5c-0.8,1.6-1.6,3.1-2.6,4.5c-2,2.9-4.3,5.4-6.9,7.7c2-2.8,3.9-5.6,5.5-8.5c1.6-2.9,3-6,3.6-9.2
					c0.6-3.2,0.4-6.5-0.9-9.4C534,1408.5,531.6,1406,528.8,1404.1z"
              />
            </g>
            <g>
              <path
                fill="#FF9A6C"
                d="M328.9,1438.5c-0.7-1-1.2-2.1-1.6-3.2c-0.4-1.1-0.6-2.3-0.7-3.5c-0.1-1.2,0-2.4,0.2-3.6
					c0.2-1.2,0.6-2.3,1.3-3.3c0.2,1.2,0.2,2.3,0.3,3.4l0.3,3.3C328.8,1433.9,329,1436.1,328.9,1438.5z"
              />
            </g>
          </g>
          <g>
            <path
              fill="#FF9A6C"
              d="M412.3,1402.9c-3.1-3.5-6.7-6.4-10.7-8.5c-4-2.1-8.3-3.5-12.8-3.9c-4.5-0.4-9,0.1-13.4,1.3
				c-4.4,1.2-8.7,3-12.8,5c3.7-2.8,7.9-5,12.4-6.5c2.2-0.8,4.6-1.3,6.9-1.6c2.4-0.3,4.8-0.3,7.1-0.2c4.8,0.4,9.4,2,13.4,4.5
				C406.4,1395.6,409.9,1398.9,412.3,1402.9z"
            />
          </g>
          <g>
            <path
              fill="#FF9A6C"
              d="M472.6,1410.3c-3.5-4.4-7.9-7.8-12.8-10.4c-4.9-2.5-10.2-4-15.6-4.3c-5.4-0.4-10.9,0.3-16.3,1.7
				c-5.3,1.3-10.6,3.3-15.7,5.6c4.7-3,9.8-5.5,15.2-7.1c1.4-0.4,2.7-0.8,4.1-1.1c1.4-0.3,2.8-0.5,4.2-0.8c2.8-0.3,5.7-0.5,8.5-0.3
				c2.8,0.2,5.7,0.7,8.4,1.5c2.7,0.8,5.4,2,7.8,3.4C465.5,1401.5,469.8,1405.5,472.6,1410.3z"
            />
          </g>
          <g>
            <path
              fill="#FF9A6C"
              d="M531.2,1405.6c-4.7-2.4-9.6-4.2-14.7-5.3c-5.1-1.1-10.2-1.6-15.4-1.2c-5.1,0.4-10.2,1.6-15,3.6
				c-4.8,2-9.3,4.7-13.6,7.7c3.7-3.7,8.1-6.8,12.9-9.2c4.8-2.3,10.1-3.7,15.5-4.1c5.4-0.4,10.8,0.2,15.9,1.7
				C522,1400.2,527,1402.4,531.2,1405.6z"
            />
          </g>
          <g>
            <path
              fill="#FF9A6C"
              d="M527.2,1369.1c-0.2,3.1-0.4,6.2-0.3,9.2c0.1,3.1,0.3,6.1,0.7,9.1c0.4,3,0.9,6,1.5,9c0.6,3,1.4,6,2.1,9
				c-1.5-2.7-2.7-5.6-3.6-8.6c-1-3-1.6-6.1-2-9.2c-0.4-3.1-0.5-6.3-0.2-9.4C525.6,1375.2,526.2,1372.1,527.2,1369.1z"
            />
          </g>
          <g>
            <path fill="none" d="M527.2,1369.1" />
          </g>
        </g>
        <g id="face">
          <g>
            <g>
              <g>
                <path
                  fill="#FFC89F"
                  d="M1029.8,864.9V745.3h-245v113.8c0,10.9-8.9,19.6-19.8,19.4l-3.3-0.1c0,0-28.8,76.6,9.3,162.3
						c0,0,63.3,38.1,99.2,110.5c0,0,64.8-80.4,108.8-100.3c0,0,67.2-60.3,69.9-166.6l0,0C1038.3,884.1,1029.8,875.5,1029.8,864.9z"
                />
              </g>
              <g>
                <path
                  fill="#FFC89F"
                  d="M907.3,916.6L907.3,916.6c-90.1,0-163.2-73.1-163.2-163.2V554.1c0-90.1,73.1-163.2,163.2-163.2h0
						c90.1,0,163.2,73.1,163.2,163.2v199.3C1070.5,843.6,997.5,916.6,907.3,916.6z"
                />
              </g>
              <g>
                <path
                  fill="#FF9A6C"
                  d="M907.3,916.6c-14.1,1.2-28.4,0-42.3-3.2c-3.5-0.8-6.9-1.8-10.3-2.8c-3.4-1-6.7-2.4-10.1-3.5
						c-1.7-0.5-3.3-1.4-4.9-2.1l-4.9-2.2c-3.2-1.5-6.3-3.3-9.5-4.9c-3.1-1.8-6.1-3.8-9.1-5.7c-3-2-5.8-4.2-8.7-6.3
						c-2.8-2.2-5.5-4.6-8.2-6.9c-2.7-2.4-5.1-5-7.7-7.4c-9.9-10.3-18.3-22-24.4-34.7c8.3,11.4,17.4,22,27.6,31.4
						c2.6,2.3,5.2,4.6,7.8,6.8c2.7,2.1,5.4,4.3,8.2,6.4c2.9,1.9,5.6,4,8.5,5.9c3,1.8,5.8,3.7,8.9,5.4c3,1.6,6,3.4,9.1,4.9
						c3.1,1.4,6.2,3,9.4,4.3c3.2,1.3,6.4,2.7,9.6,3.8l4.9,1.6c1.6,0.5,3.3,1.2,4.9,1.6C879.5,912.9,893.3,915.4,907.3,916.6z"
                />
              </g>
              <g>
                <path
                  fill="#FF9A6C"
                  d="M1053.1,826.8c-2.2,7.2-5.5,14-9.4,20.4c-1,1.6-2,3.2-3,4.7l-3.3,4.6c-1.1,1.5-2.3,2.9-3.5,4.4
						l-3.7,4.3c-5.1,5.6-10.7,10.7-16.7,15.1c-6,4.5-12.5,8.4-19.4,11.3c5.6-5,11.1-9.8,16.5-14.8c5.3-5,10.4-10.2,15.3-15.6l3.7-4
						c1.2-1.4,2.3-2.8,3.5-4.2l3.5-4.2c1.1-1.5,2.3-2.9,3.4-4.3C1044.4,838.8,1048.7,832.9,1053.1,826.8z"
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  fill="#FFC89F"
                  d="M738.5,747h8.5c16.8,0,30.4-13.6,30.4-30.4v-54.2c0-16.8-13.6-30.4-30.4-30.4h-8.5
						c-16.8,0-30.4,13.6-30.4,30.4v54.2C708.1,733.4,721.7,747,738.5,747z"
                />
              </g>
              <g>
                <path
                  fill="#FFC89F"
                  d="M1067.7,747h8.5c16.8,0,30.4-13.6,30.4-30.4v-54.2c0-16.8-13.6-30.4-30.4-30.4h-8.5
						c-16.8,0-30.4,13.6-30.4,30.4v54.2C1037.4,733.4,1051,747,1067.7,747z"
                />
              </g>
              <g>
                <g>
                  <path
                    fill="#FF9A6C"
                    d="M736.1,664.2c-3.3,1.1-5.5,3.6-6.8,6.3c-1.4,2.7-2.2,5.8-2.7,8.9c-0.8,6.2-0.4,12.6,1.3,18.5
							c0.9,2.9,2.2,5.7,3.9,7.9c0.8,1.1,2,2.2,2.7,2.6c0.2,0.1,0.2,0.1,0.2,0.1c0,0-0.1,0.1-0.1,0.1c0.1,0.1,0.4-0.3,0.7-0.9
							c0.6-1.1,0.9-2.7,1.2-4.2c0.3-1.6,0.5-3.2,0.7-4.8c0.4-3.3,0.5-6.6,0.8-10c1,3.3,1.6,6.6,2,10c0.2,1.7,0.3,3.4,0.3,5.2
							c0,1.8-0.2,3.6-0.8,5.6c-0.4,1-0.8,2.1-2.2,3.2c-0.7,0.5-1.6,0.8-2.4,0.9c-0.9,0.1-1.6-0.1-2.2-0.3c-2.4-1-3.7-2.3-5-3.7
							c-2.6-2.8-4.3-6.3-5.4-9.7c-2.1-7-2.2-14.3-0.3-21.2c1-3.4,2.5-6.8,4.8-9.6c1.2-1.4,2.6-2.6,4.2-3.5
							C732.6,664.6,734.3,664.1,736.1,664.2z"
                  />
                </g>
                <g>
                  <path
                    fill="#FF9A6C"
                    d="M742,678.5c-0.9-2.1-1.3-4.1-1.6-6c-0.3-1.9-0.6-3.7-0.8-5.3c-0.2-1.7-0.5-3.2-0.9-4.3
							c-0.2-0.6-0.5-1.1-1.1-1.6c-0.6-0.5-1.5-0.9-2.6-1.3c0.8-0.8,1.9-1.4,3.2-1.5c1.3-0.1,2.8,0.4,3.9,1.3c2.2,1.9,3,4.4,3.3,6.6
							c0.3,2.2,0.1,4.4-0.4,6.4C744.4,674.8,743.5,676.8,742,678.5z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    fill="#FF9A6C"
                    d="M1079.9,664.2c1.7-0.1,3.5,0.3,5.1,1.2c1.6,0.8,3,2.1,4.2,3.5c2.3,2.8,3.8,6.2,4.8,9.6
							c1.9,6.9,1.8,14.2-0.3,21.2c-1.1,3.5-2.8,6.9-5.4,9.7c-1.4,1.4-2.6,2.7-5,3.7c-0.6,0.2-1.4,0.4-2.2,0.3
							c-0.9,0-1.8-0.4-2.4-0.9c-1.3-1-1.8-2.2-2.2-3.2c-0.7-2-0.8-3.8-0.8-5.6c0-1.8,0.1-3.5,0.3-5.2c0.4-3.4,1-6.7,2-10
							c0.2,3.4,0.4,6.7,0.8,10c0.2,1.6,0.4,3.3,0.7,4.8c0.3,1.5,0.7,3.1,1.2,4.2c0.3,0.5,0.7,0.9,0.7,0.9c0,0-0.1-0.1-0.1-0.1
							c0,0,0.1,0,0.2-0.1c0.7-0.3,1.9-1.5,2.7-2.6c1.8-2.2,3-5,3.9-7.9c1.7-5.9,2.1-12.3,1.3-18.5c-0.5-3.1-1.2-6.2-2.7-8.9
							C1085.4,667.8,1083.2,665.4,1079.9,664.2z"
                  />
                </g>
                <g>
                  <path
                    fill="#FF9A6C"
                    d="M1074,678.5c-1.5-1.7-2.4-3.7-3-5.7c-0.5-2-0.7-4.2-0.4-6.4c0.3-2.2,1.1-4.7,3.3-6.6
							c1.1-0.9,2.6-1.4,3.9-1.3c1.3,0.2,2.4,0.7,3.2,1.5c-1.1,0.4-2,0.8-2.6,1.3c-0.6,0.5-0.9,1-1.1,1.6c-0.4,1.1-0.7,2.6-0.9,4.3
							c-0.2,1.7-0.5,3.5-0.8,5.3C1075.3,674.5,1074.8,676.4,1074,678.5z"
                  />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <circle fill="#1B1B2F" cx="994.8" cy="643.2" r="14.7" />
                </g>
                <g>
                  <path
                    fill="#1B1B2F"
                    d="M1025.1,619.9c-4.7-2.9-9.6-5.2-14.6-6.8c-5.1-1.5-10.3-2.4-15.5-2.2c-1.3-0.1-2.6,0.2-3.9,0.2l-2,0.2
							c-0.7,0.1-1.3,0.3-1.9,0.4l-3.9,0.7c-1.3,0.4-2.5,0.8-3.8,1.1l-1.9,0.6l-1.9,0.8l-3.8,1.5c-2.5,1.1-4.8,2.4-7.4,3.5
							c2.1-1.8,4.2-3.6,6.5-5.2l3.6-2.1l1.8-1l1.9-0.8c1.3-0.5,2.6-1.1,3.9-1.6c1.4-0.4,2.7-0.7,4.1-1.1c0.7-0.2,1.4-0.4,2.1-0.5
							l2.1-0.2c1.4-0.1,2.8-0.4,4.3-0.3c5.7-0.1,11.4,1.1,16.6,3.3C1016.7,612.5,1021.4,615.7,1025.1,619.9z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <circle fill="#1B1B2F" cx="840.6" cy="643.2" r="14.7" />
                </g>
                <g>
                  <path
                    fill="#1B1B2F"
                    d="M870.9,619.9c-4.7-2.9-9.6-5.2-14.6-6.8c-5.1-1.5-10.3-2.4-15.5-2.2c-1.3-0.1-2.6,0.2-3.9,0.2l-2,0.2
							c-0.7,0.1-1.3,0.3-1.9,0.4l-3.9,0.7c-1.3,0.4-2.5,0.8-3.8,1.1l-1.9,0.6l-1.9,0.8l-3.8,1.5c-2.5,1.1-4.8,2.4-7.4,3.5
							c2.1-1.8,4.2-3.6,6.5-5.2l3.6-2.1l1.8-1l1.9-0.8c1.3-0.5,2.6-1.1,3.9-1.6c1.4-0.4,2.7-0.7,4.1-1.1c0.7-0.2,1.4-0.4,2.1-0.5
							l2.1-0.2c1.4-0.1,2.8-0.4,4.3-0.3c5.7-0.1,11.4,1.1,16.6,3.3C862.5,612.5,867.3,615.7,870.9,619.9z"
                  />
                </g>
              </g>
              <g>
                <path
                  fill="#1B1B2F"
                  d="M935.2,676.8c1.7,6.2,3,12.4,4.2,18.7c1.2,6.2,2.2,12.5,3.1,18.8c0.4,3.1,1,6.5,0.6,9.9
						c-0.3,3.4-1.2,6.7-2.8,9.7c-1.5,3-3.9,5.8-6.9,7.4c-2.9,1.4-5.7,2.8-8.7,4c2.5-2,5.1-3.8,7.7-5.7c2.4-1.7,4-4.3,5.2-7
						c1.1-2.8,1.7-5.7,1.8-8.7c0.2-3-0.3-5.9-0.8-9.1c-0.8-6.3-1.7-12.6-2.2-18.9C935.7,689.5,935.3,683.2,935.2,676.8z"
                />
              </g>
              <g>
                <path
                  fill="#1B1B2F"
                  d="M1025.1,588c-6.8-0.4-11.9-0.8-16.8-1c-4.8-0.2-9-0.3-13.3-0.2c-0.5,0-1.1,0-1.6,0l-1.6,0.1
						c-0.5,0-1.1,0-1.6,0c-0.5,0-1.1,0.1-1.6,0.1c-1.1,0.1-2.2,0.1-3.4,0.1c-1.1,0.1-2.3,0.2-3.6,0.3c-0.6,0-1.2,0-1.9,0
						c-0.6,0.1-1.2,0.2-1.9,0.2c-1.3,0.1-2.6,0.2-4.1,0.2c-2.8,0.2-5.7,0.4-9.3,0.1c0.5-1.6,1.2-3.1,2-4.5c0.8-1.4,1.6-2.8,2.6-4.1
						c1-1.2,2.1-2.3,3.3-3.4c0.6-0.6,1.2-1.1,1.8-1.6c0.6-0.5,1.3-0.9,2-1.4c1.3-0.9,2.8-1.7,4.2-2.5c1.5-0.6,3-1.2,4.6-1.7
						c0.8-0.3,1.6-0.5,2.4-0.7l2.5-0.4c1.7-0.2,3.3-0.5,5-0.5c6.7-0.2,13.4,1.8,18.9,5.3c0.7,0.4,1.3,1,2,1.4c0.6,0.5,1.3,1,1.9,1.5
						c1.2,1.1,2.3,2.3,3.3,3.6C1022.7,581.7,1024.4,584.6,1025.1,588z"
                />
              </g>
              <g>
                <path
                  fill="#1B1B2F"
                  d="M870.9,588c-6.8-0.4-11.9-0.8-16.8-1c-4.8-0.2-9-0.3-13.3-0.2c-0.5,0-1.1,0-1.6,0l-1.6,0.1
						c-0.5,0-1.1,0-1.6,0c-0.5,0-1.1,0.1-1.6,0.1c-1.1,0.1-2.2,0.1-3.4,0.1c-1.1,0.1-2.3,0.2-3.6,0.3c-0.6,0-1.2,0-1.9,0
						c-0.6,0.1-1.2,0.2-1.9,0.2c-1.3,0.1-2.6,0.2-4.1,0.2c-2.8,0.2-5.7,0.4-9.3,0.1c0.5-1.6,1.2-3.1,2-4.5c0.8-1.4,1.6-2.8,2.6-4.1
						c1-1.2,2.1-2.3,3.3-3.4c0.6-0.6,1.2-1.1,1.8-1.6c0.6-0.5,1.3-0.9,2-1.4c1.3-0.9,2.8-1.7,4.2-2.5c1.5-0.6,3-1.2,4.6-1.7
						c0.8-0.3,1.6-0.5,2.4-0.7l2.5-0.4c1.7-0.2,3.3-0.5,5-0.5c6.7-0.2,13.4,1.8,18.9,5.3c0.7,0.4,1.3,1,2,1.4c0.6,0.5,1.3,1,1.9,1.5
						c1.2,1.1,2.3,2.3,3.3,3.6C868.6,581.7,870.2,584.6,870.9,588z"
                />
              </g>
            </g>
          </g>
          <g>
            <path
              fill="#1B1B2F"
              d="M764.4,551.6l6.5-3.4l-6.5,39.7l-19.6,19.9c0,0,3.3,77.6,2.2,77.6c-1.1,0-27.2-74.2-27.2-135
				c0,0-19.5,17.7,5.9,46c0,0-35.5-5.2-18.7-35.8c6-11,11.5-22.3,15.7-34.1c0.2-0.4,0.3-0.9,0.5-1.3l-9.4,12.8
				c0,0-6.9-12.8,7.7-34.8c0,0-32.2,24.2-32.6,58.6c0,0-12.4-40.1,27-67.8c0,0-4.5-23.8,20.1-60.4c0,0-18.1,12.4-23.7,30.9
				c0,0-11.6-44.2,58.8-60.1c0,0,12.2-34.9,58.7-17.7c0,0-15.8-54.1,49.5-62.3c0,0-48.8,13.8-39.8,57.3c0,0,20.9-71.6,89.6-45
				c0,0,21.4-4.1,32.4,20.4c0,0,36.8,18.9,59.3,0c0,0-8.5,23.5-33.9,11.8c0,0,14.9,10.2,33.9,8.2c18.9-2,26.9,17.9,26.9,17.9
				s46.7-4.5,45.2,51.3c0,0,63.3,36.3,44.7,86.4c0,0,2.6-43.6-49.1-67.6c0,0,58.2,62.6,32.2,93.5c0,0,5.3-19.7-15.5-40.1l-11.8,38
				c0,0,20.8,32.2,10.6,46c0,0-4.1-32.2-12.2-43.5c0,0,5.6,66.4-22.9,102.7l1.4-51.5l-17.9-27.8l-4.6-56.7c0,0-40-25.5-38.6-25.5
				c1.4,0,11.5-3.8,11.5-3.8s-18.4-36.3-70.8-18.4l1.4-6.1l-20.2,9l4.1-12.3l-17,12.3l-17-5.7l3.7,6.6l-33.6-9l4.1,5.2
				c0,0-62.5,2.8-103,45.3l10.6-3.3L764.4,551.6z"
            />
          </g>
          <g>
            <path
              fill="#1F4068"
              d="M828.4,366.9c-0.2,4.9,0.5,9.6,1.5,14.3c0.4,2.4,1.2,4.6,1.8,6.9c0.7,2.3,1.6,4.5,2.3,6.8
				c3.5,8.9,8.2,17.3,13.6,25.1c5.2,8,11.5,15.3,17.8,22.6l10,10.5c3.5,3.3,7,6.7,10.4,10.2c-4-2.8-7.8-5.8-11.7-8.7
				c-3.7-3.2-7.3-6.4-10.9-9.8c-1.7-1.8-3.4-3.5-5.1-5.3c-1.7-1.8-3.4-3.5-4.9-5.5l-4.6-5.7c-1.6-1.9-2.8-4-4.3-6
				c-5.5-8.1-10.2-16.9-13.2-26.3c-0.7-2.4-1.4-4.7-1.9-7.1c-0.4-2.4-1-4.8-1.2-7.3C827.5,376.7,827.3,371.7,828.4,366.9z"
            />
          </g>
          <g>
            <path
              fill="#1F4068"
              d="M860.7,361.1c-1.5,4-2.3,8.1-2.8,12.2c-0.6,4.1-0.6,8.3-0.4,12.4c0.4,8.2,2.1,16.4,4.6,24.2
				c0.7,2,1.2,3.9,2,5.9l2.4,5.7c0.4,1,0.7,1.9,1.2,2.9l1.4,2.8c0.9,1.9,1.8,3.7,2.7,5.6l6.1,10.9c2.3,3.5,4.5,7.1,6.7,10.7
				c-2.8-3.1-5.5-6.4-8.3-9.7c-2.4-3.5-4.9-6.9-7.2-10.5c-4.3-7.3-8.3-15-10.7-23.2c-2.5-8.2-4.1-16.8-3.9-25.4
				c0.2-4.3,0.6-8.6,1.6-12.8C857.1,368.8,858.5,364.7,860.7,361.1z"
            />
          </g>
          <g>
            <path
              fill="#1F4068"
              d="M931.1,458.9c4.5-5.5,8.7-11.2,12.7-17c3.9-5.8,7.4-11.9,10.5-18.1l2.2-4.7c0.7-1.6,1.2-3.2,1.9-4.8
				c0.3-0.8,0.7-1.6,1-2.4l0.8-2.5l0.8-2.5c0.3-0.8,0.6-1.7,0.7-2.5c1.8-6.7,2.5-13.7,2.7-20.8c1.3,7,1.5,14.3,0.2,21.5
				c-1.3,7.1-3.6,14.1-6.7,20.6c-3.2,6.5-7.1,12.6-11.6,18.2C941.7,449.3,936.7,454.5,931.1,458.9z"
            />
          </g>
          <g>
            <path
              fill="#1F4068"
              d="M913.8,457.4c-1.5-3.5-2.4-7.1-3.1-10.8c-0.6-3.7-1-7.4-1-11.2c0-3.8,0.4-7.5,1.1-11.2
				c0.7-3.7,1.9-7.3,3.6-10.7c-0.1,3.8-0.4,7.4-0.5,11c-0.1,3.6-0.2,7.2-0.2,10.9l0.1,10.9C913.9,450,913.9,453.6,913.8,457.4z"
            />
          </g>
          <g>
            <path
              fill="#1F4068"
              d="M946.2,449.8c6.7-8,14.3-15.1,22.2-21.8c8-6.7,16.5-12.8,25.5-18.1c9-5.2,18.6-9.8,28.7-12.6
				c10.1-2.9,20.9-4,31.1-2.1c-10.4-0.3-20.5,1.6-30.2,5c-4.8,1.7-9.6,3.6-14.1,5.9c-4.6,2.2-9.1,4.6-13.5,7.2
				c-8.9,5.1-17.3,11-25.6,17.1C962.1,436.6,954,443,946.2,449.8z"
            />
          </g>
          <g>
            <path
              fill="#1F4068"
              d="M999.7,434.5c8.2-4.2,17.4-6.1,26.6-6.8c9.2-0.7,18.5,0,27.6,1.7c9.1,1.8,18,4.6,26.3,8.6
				c8.3,4,16.1,9.1,22.7,15.5c-7.6-5.1-15.6-9.3-24-12.7c-8.3-3.3-17-5.9-25.8-7.5c-8.8-1.7-17.8-2.5-26.8-2.5
				C1017.5,430.8,1008.5,431.8,999.7,434.5z"
            />
          </g>
          <g>
            <path
              fill="#1F4068"
              d="M1116.7,534.6c-3.7-4.9-7.6-9.6-11.7-14c-4.1-4.4-8.3-8.7-12.9-12.7c-4.5-4-9.2-7.8-14.1-11.3
				c-4.9-3.6-10.1-6.7-15.2-10.1c5.9,1.9,11.6,4.3,16.9,7.5c2.7,1.5,5.2,3.3,7.8,5.1c1.3,0.9,2.4,1.9,3.7,2.9c1.2,1,2.4,1.9,3.6,3
				c4.6,4.1,8.9,8.7,12.6,13.7C1111.1,523.6,1114.4,528.9,1116.7,534.6z"
            />
          </g>
          <g>
            <path
              fill="#1F4068"
              d="M749.2,411.2c4.4-3,9.5-5.1,14.7-6.5c1.3-0.3,2.6-0.7,3.9-1l4-0.7c2.7-0.3,5.4-0.6,8.1-0.6
				c1.4,0,2.7,0,4.1,0.1c1.4,0.1,2.7,0.2,4.1,0.3c2.7,0.5,5.4,0.8,7.9,1.7c5.2,1.3,10.2,3.7,14.6,6.6c-5.2-1.4-10.2-2.9-15.3-3.6
				c-5.1-0.9-10.2-1.3-15.4-1.2c-5.1,0-10.3,0.4-15.4,1.3C759.4,408.5,754.3,409.7,749.2,411.2z"
            />
          </g>
          <g>
            <path
              fill="#1F4068"
              d="M868.7,469.8c-7.8-3.7-15.8-6.9-23.8-9.7c-4-1.4-8.1-2.7-12.2-3.7c-4.1-1.1-8.2-2-12.4-2.5
				c-4.2-0.6-8.4-0.9-12.5-0.8c-4.2,0-8.3,0.7-12.3,1.8c-4,1.2-7.8,2.8-11.3,5.2c-3.5,2.4-6.5,5.3-9.4,8.6c2.1-3.8,4.9-7.2,8.2-10.2
				c3.4-2.9,7.4-5.1,11.6-6.6c8.5-3.1,17.6-3.1,26.3-2c8.7,1.3,17.2,3.8,25.2,7.2C854,460.6,861.7,464.7,868.7,469.8z"
            />
          </g>
          <g>
            <path
              fill="#1F4068"
              d="M874.9,478.1c-7-0.7-13.9-1.1-20.9-1.2c-7-0.2-13.9-0.1-20.8,0.2c-6.9,0.4-13.8,0.9-20.7,1.9
				c-6.9,0.9-13.7,2.1-20.4,3.8c-6.7,1.6-13.3,3.6-19.7,6.1c-6.4,2.5-12.6,5.5-18.6,9c-5.9,3.5-11.5,7.6-16.7,12.2
				c-5.2,4.6-9.7,9.9-14.1,15.4c3.6-6,7.9-11.6,12.7-16.7c5-5,10.4-9.5,16.4-13.4c6-3.8,12.3-7,18.8-9.8c6.6-2.6,13.3-4.7,20.2-6.5
				c13.8-3.3,27.9-4.8,41.9-4.9C847.2,474,861.2,475.2,874.9,478.1z"
            />
          </g>
          <g>
            <path
              fill="#1F4068"
              d="M703.3,505c2.9-4.1,6.6-7.6,10.6-10.7c4-3.1,8.4-5.7,13-7.8c2.3-1.1,4.7-1.9,7.1-2.7
				c2.5-0.6,4.9-1.5,7.4-1.8c5-1,10.1-1.1,15.1-0.6c-4.9,1.1-9.7,2-14.4,3.6c-4.7,1.4-9.2,3.1-13.6,5.1c-4.4,1.9-8.7,4.2-12.9,6.7
				C711.4,499.4,707.3,502.1,703.3,505z"
            />
          </g>
          <g>
            <path
              fill="#1F4068"
              d="M1037.6,519.6c4.9,1.8,9.5,4.1,14,6.5c4.5,2.5,8.8,5.2,13.1,8.1c4.2,2.9,8.4,6,12.3,9.3
				c4,3.3,7.7,6.8,11.2,10.6c-4.5-2.5-8.8-5.3-13-8.1c-4.3-2.8-8.5-5.6-12.7-8.5c-4.2-2.9-8.4-5.7-12.5-8.7
				C1045.8,525.8,1041.7,522.8,1037.6,519.6z"
            />
          </g>
          <g>
            <path
              fill="#1F4068"
              d="M714.9,557.7c0.6-3.5,2.5-6.7,4.5-9.6c2.1-2.9,4.6-5.4,7.3-7.7c2.7-2.3,5.6-4.2,8.7-6
				c1.5-0.9,3.1-1.6,4.7-2.3c1.6-0.6,3.3-1.3,5-1.8c-1.3,1.2-2.6,2.3-4,3.4c-1.3,1.1-2.7,2.2-4,3.2c-2.6,2.2-5.3,4.2-7.8,6.4
				c-2.6,2.2-5.1,4.4-7.5,6.7C719.3,552.4,717,554.8,714.9,557.7z"
            />
          </g>
          <g>
            <path
              fill="#1F4068"
              d="M735.4,593.3c2.1-4.6,4.6-9,7.2-13.4c2.6-4.4,5.3-8.6,8.1-12.8c2.9-4.2,5.8-8.3,8.8-12.3
				c3.1-4,6.2-8,9.7-11.7c-2.2,4.7-4.6,9.1-7.2,13.4c-2.6,4.4-5.3,8.6-8.1,12.8c-2.9,4.2-5.7,8.3-8.8,12.3
				C742.1,585.6,738.9,589.5,735.4,593.3z"
            />
          </g>
          <g>
            <g>
              <g>
                <ellipse
                  fill="#FF9A6C"
                  cx="913.8"
                  cy="790.9"
                  rx="29.5"
                  ry="22.9"
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  fill="#E3405A"
                  d="M937.1,805c-2.3,3.4-5.7,6-9.5,7.8c-3.8,1.8-7.9,2.8-12.1,2.9c-4.2,0.2-8.4-0.4-12.3-1.9
						c-3.9-1.4-7.6-3.7-10.2-6.8c3.7,1.8,7.3,3,11.1,3.8c3.7,0.8,7.5,1,11.3,0.9C922.9,811.5,930.3,809.4,937.1,805z"
                />
              </g>
            </g>
          </g>
        </g>
        <g id="right-hand">
          <g id="fix-part">
            <path
              fill="#FF9A6C"
              d="M1353.5,945.7c2.5,6,5,11.9,7.8,17.7c2.7,5.9,5.6,11.7,8.6,17.4c6,11.4,12.3,22.7,19.3,33.5
				c6.9,10.9,14.4,21.4,22.6,31.4c8.1,10,17,19.4,26.7,28c-2.5-2.1-5.1-4-7.4-6.2c-1.2-1.1-2.4-2.1-3.6-3.2l-3.5-3.3
				c-4.6-4.6-9.1-9.3-13.3-14.2c-4.3-4.9-8.2-10-12.1-15.3c-3.8-5.2-7.5-10.6-11-16c-3.5-5.5-6.8-11-10-16.7
				c-3.2-5.7-6.2-11.4-9.1-17.2c-2.8-5.8-5.5-11.7-8.1-17.7c-1.3-3-2.5-6-3.6-9C1355.6,951.8,1354.5,948.8,1353.5,945.7z"
            />
            <path
              fill="#FFC89F"
              d="M1618.6,951.2c0,0-289.1-318.5-347.5-352.7c0,0-15.4-7.3-39.9-24.8l-63.9,79.6
				c16.7,18.6,55.2,50.2,87.4,108c57.5,82.1,98.7,184.4,98.7,184.4s-83.6,43.1-93.6,131.1c-10,88,77.3,94.4,77.3,94.4
				s172.5,39.3,263.3-43C1600.4,1128.2,1717.9,1057.7,1618.6,951.2z"
            />
          </g>
          <g id="top-part">
            <g>
              <path
                fill="#FFC89F"
                d="M1327.1,713.3c25.2-21.9-17.8-98.2-49.5-121.4c0,0-72.7-80.8-162.6-69.7l-128.3-8.6l-18.5,6.4l-63.7-1.7
					c0,0-39.4,5-74.2-3.5c-5.1-1.2-9.7,3.5-8.3,8.5c0.7,2.4,1.8,4.7,3.6,6.6c0,0-29,1.5-49.2-1.9c-5.8-1-10.8,4.2-9.4,9.9
					c0.4,1.7,1,3.5,1.9,5.5l-22.1,0.1c-6.7,0-11,7-8.2,13c2.7,5.6,8.4,11.3,20.2,12.9c0,0,0.2,18,50,10.9l55.2,1.7l56.9-1.4
					c0,0,36.1,15.7,79.5,14.4l60.5,21.3c0,0,33.6,36.7,83.9,18.5c0,0,86.8,78.7,112.5,85.2c9.5,2.4,30.3,12.1,54,5.5"
              />
            </g>
            <g>
              <path
                fill="#FF9A6C"
                d="M921,580.6c11.5,3.7,23.2,6.7,35,9.3c2.9,0.6,5.9,1.3,8.9,1.9c3,0.6,5.9,1.1,8.9,1.5
					c1.5,0.2,3,0.4,4.5,0.5c1.5,0.2,3,0.3,4.5,0.4c3,0.2,6,0.3,9.1,0.4c6,0.2,12.2,0.5,18.2,1.9c3,0.7,6,1.6,8.8,2.7
					c2.8,1,5.7,2.1,8.5,3.2c11.3,4.3,22.5,8.9,33.7,13.7l-0.1,0.2c-11.6-3.7-23-7.7-34.3-11.9c-2.8-1.1-5.7-2.1-8.5-3.2
					c-2.8-1.2-5.6-2.1-8.5-2.9c-5.8-1.5-11.8-2.1-17.8-2.7c-12.1-1.1-24.1-2.2-36.1-4.2c-6-1.1-11.9-2.6-17.7-4.4
					c-5.8-1.8-11.5-3.8-17-6.4L921,580.6z"
              />
            </g>
            <g>
              <path
                fill="#FF9A6C"
                d="M758.9,569.5c0.2,1.1,0.6,2.2,1.3,3.1l0.5,0.7l0.6,0.6l0.6,0.6c0.2,0.2,0.4,0.4,0.6,0.5
					c0.8,0.8,1.8,1.3,2.7,1.9c0.5,0.3,1,0.5,1.5,0.7c0.5,0.2,1,0.5,1.5,0.7c4.2,1.6,8.8,2.1,13.3,2.5c2.3,0.1,4.5,0.3,6.8,0.2
					c2.3,0,4.6,0,6.8-0.2c2.3-0.2,4.6-0.1,6.8-0.4l6.8-0.6l0.1,0l0,0c4.7,0.3,9.3,0.5,14,0.6l7,0.2l7,0.1c9.3,0,18.7,0.2,28,0.4
					c9.3-0.4,18.7-0.5,28-0.6c9.3-0.1,18.7-0.1,28,0.1l0,0.2c-9.3,0.7-18.7,1.1-28,1.5c-9.3,0.4-18.7,0.7-28,0.8
					c-9.3-0.3-18.7-0.7-28-1.3l-7-0.4l-7-0.3c-4.7-0.2-9.3-0.3-14-0.3l0.1,0c-4.6,0.7-9.1,1.4-13.7,1.8c-1.2,0.1-2.3,0.2-3.5,0.2
					l-3.5,0c-2.3,0.1-4.6-0.1-6.9-0.3c-4.6-0.5-9.3-1.2-13.6-3.1c-0.5-0.2-1-0.5-1.6-0.8c-0.5-0.3-1.1-0.5-1.5-0.9l-1.4-1
					c-0.5-0.3-0.9-0.8-1.3-1.2c-0.2-0.2-0.4-0.4-0.6-0.6l-0.5-0.7l-0.5-0.7l-0.4-0.8c-0.6-1-1-2.2-1-3.4L758.9,569.5z"
              />
            </g>
            <g>
              <path
                fill="#FF9A6C"
                d="M768.9,543.6c-5.4,0.4-10.9,0.7-16.3,0.8l-4.1,0.1c-0.7,0-1.4,0-2,0.1c-0.6,0-1.2,0.2-1.8,0.3
					c-2.3,0.7-4.3,2.5-5.2,4.7c-0.5,1.1-0.7,2.3-0.7,3.5c0.1,1.2,0.4,2.4,1,3.5c1.1,2.4,2.7,4.4,4.7,6.2c3.9,3.5,9.1,5.6,14.4,6.6
					c-2.7-0.1-5.5-0.6-8.1-1.5c-2.6-0.9-5.2-2.2-7.4-4c-2.2-1.8-4-4-5.4-6.5c-0.7-1.3-1.2-2.8-1.2-4.3c-0.1-1.5,0.2-3,0.8-4.4
					c1.2-2.8,3.6-5,6.6-5.8c0.7-0.2,1.5-0.3,2.2-0.3c0.8,0,1.4,0,2.1,0l4.1,0.1C758,543,763.4,543.2,768.9,543.6z"
              />
            </g>
            <g>
              <path
                fill="#FF9A6C"
                d="M825.6,530.1c-5.9,0.7-11.9,1-17.8,1.1c-6,0.1-11.9,0-17.9-0.5c-3-0.2-5.9-0.5-8.9-0.9
					c-1.5-0.2-3-0.5-4.4-0.7c-1.4-0.3-2.7-0.2-4,0.2c-2.6,0.8-4.7,3.1-5.2,5.9c-0.3,1.4-0.1,2.8,0.3,4.2c0.4,1.4,0.8,2.8,1.3,4.2
					c-0.7-1.3-1.3-2.7-1.8-4.1c-0.5-1.4-0.9-3-0.7-4.5c0.3-3.2,2.6-6,5.7-7.2c1.5-0.6,3.2-0.7,4.8-0.4c1.5,0.2,2.9,0.4,4.4,0.6
					c2.9,0.3,5.9,0.6,8.8,0.8c5.9,0.5,11.8,0.7,17.7,0.9l8.9,0.2C819.6,530,822.6,530,825.6,530.1z"
              />
            </g>
            <g>
              <path
                fill="#FF9A6C"
                d="M1011.4,515.5c-2.2,0-4.5-0.1-6.7-0.2c-2.2-0.1-4.5-0.1-6.7-0.3c-4.5-0.3-8.9,0-13.2,0.8
					c-2.2,0.4-4.3,1-6.4,1.6c-2.1,0.7-4.3,1.5-6.5,2c-4.4,1-9.1,1.4-13.6,1.2c-9-0.4-17.9-0.9-26.9-1.2c-4.5-0.1-9-0.2-13.5-0.3
					l-13.5-0.1l0.1,0c-8,1.1-16.1,1.6-24.1,1.8c-8.1,0.2-16.2,0-24.2-0.8c-8-0.7-16.1-2-23.9-3.9l-1.5-0.4l-0.7-0.2l-0.7-0.1
					c-0.9-0.1-1.8-0.1-2.7,0.2c-1.8,0.5-3.3,1.9-4,3.6c-0.4,0.9-0.5,1.8-0.5,2.7c0,0.9,0.3,1.9,0.6,2.8c0.6,1.9,1.6,3.7,2.9,5.2
					l-0.1,0.1c-1.5-1.4-2.6-3.2-3.3-5.1c-0.4-0.9-0.7-1.9-0.8-3c-0.1-1.1,0-2.2,0.4-3.2c0.8-2,2.4-3.7,4.5-4.4
					c1-0.4,2.2-0.5,3.3-0.4l0.8,0.1l0.7,0.1l1.5,0.3c7.8,1.7,15.8,2.7,23.8,3.3c8,0.6,16,0.9,24,0.9c8-0.1,16-0.4,24-1.1l0.1,0l0,0
					l13.5,0.6c4.5,0.2,9,0.3,13.5,0.5c9,0.2,18,0.2,26.9,0.2c4.4,0.1,8.8-0.4,13.1-1.4c2.2-0.5,4.2-1.1,6.4-1.8
					c2.2-0.7,4.4-1.2,6.7-1.6c4.5-0.8,9.1-0.8,13.6-0.4c2.2,0.2,4.5,0.4,6.7,0.6c2.2,0.2,4.5,0.4,6.7,0.7L1011.4,515.5z"
              />
            </g>
            <g>
              <path
                fill="#FF9A6C"
                d="M826.6,531c1,1.6,2.2,2.9,3.6,4.2c1.4,1.2,2.8,2.4,4.3,3.5c3,2.1,6.2,3.9,9.6,5.4
					c3.3,1.6,6.8,2.9,10.3,4.1c3.5,1.2,7,2.3,10.6,3.2c7.2,1.9,14.6,2.9,22.1,3.3c7.4,0.4,14.9,0.4,22.3,0.4l0.1,0l0.1,0
					c4,0.7,8.1,1.3,12.1,1.9c4,0.6,8.1,1.2,12.2,1.7c4.1,0.5,8.1,1,12.2,1.5c4.1,0.4,8.2,0.8,12.3,1l0,0.2c-4.1,0.2-8.2,0.2-12.3,0
					c-4.1-0.2-8.2-0.5-12.3-0.9c-4.1-0.4-8.2-0.9-12.3-1.5c-4.1-0.6-8.1-1.3-12.2-2.1l0.2,0c-7.5,0-15-0.5-22.4-1.4
					c-7.4-0.9-14.8-1.9-22.2-3.2c-7.4-1.4-14.7-3.3-21.5-6.7c-3.4-1.7-6.6-3.6-9.6-6c-1.5-1.2-2.9-2.5-4.1-3.9
					c-1.2-1.4-2.4-2.9-3.2-4.7L826.6,531z"
              />
            </g>
            <g>
              <path
                fill="#FF9A6C"
                d="M1062.4,611.8c-8-4.9-16.1-9.6-24.2-14.3c-4-2.4-8-4.8-12.2-6.9c-4.2-2.1-8.5-4-12.9-5.4
					c-4.5-1.4-9-2.6-13.5-3.5c-2.3-0.5-4.6-0.8-6.9-1.1c-2.4-0.3-4.7-0.8-7-1.4c-4.6-1.3-9.1-3.1-13.2-5.5
					c-4.1-2.4-7.8-5.6-10.8-9.2l0.1-0.1c6.9,6.4,15.4,10.5,24.4,12.8c2.2,0.6,4.5,1,6.8,1.4c2.3,0.4,4.7,0.8,7,1.4
					c4.6,1,9.2,2.5,13.5,4.3c2.2,0.9,4.3,1.9,6.5,2.9l6.4,3l6.4,3c2.1,1,4.3,2,6.3,3.1c4.2,2.2,8.2,4.6,12.1,7.2
					c3.9,2.6,7.8,5.3,11.4,8.4L1062.4,611.8z"
              />
            </g>
            <g>
              <path
                fill="#FF9A6C"
                d="M769.4,544.4c1.3,2.4,3.1,4.5,5.1,6.4c2,1.9,4.2,3.5,6.6,4.8c4.7,2.7,10,4.4,15.3,5.5
					c5.4,1,10.8,1.7,16.4,1.7c5.5,0.1,11-0.3,16.5-1.1l0,0l0,0c4,0.3,8.1,0.5,12.1,0.7l12.1,0.6c4,0.2,8.1,0.5,12.1,0.7l12.1,0.8
					l0.1,0l0.1,0c1.9,0.4,3.8,0.7,5.7,1.1c1.9,0.3,3.8,0.7,5.8,1c3.9,0.6,7.7,1.2,11.6,1.7c3.9,0.5,7.8,0.9,11.7,1.2
					c3.9,0.3,7.8,0.5,11.7,0.6l0,0.2c-3.9,0.3-7.9,0.4-11.8,0.3c-3.9-0.1-7.9-0.3-11.8-0.6c-3.9-0.4-7.8-0.7-11.7-1.3
					c-3.9-0.5-7.8-1.2-11.7-2l0.1,0l-12.1-1c-4-0.3-8-0.7-12.1-1.1l-12.1-1.2c-4-0.4-8-0.7-12.1-1.1l0.1,0c-5.5,0.8-11,1.4-16.6,1.7
					c-5.6,0.2-11.2,0-16.7-1.1c-2.8-0.6-5.5-1.3-8.1-2.3c-2.6-1-5.2-2.2-7.6-3.7c-2.4-1.5-4.6-3.3-6.6-5.3c-1.9-2.1-3.6-4.4-4.6-7
					L769.4,544.4z"
              />
            </g>
            <g>
              <path
                fill="#FF9A6C"
                d="M760.9,569.4l22.2-0.6c3.7-0.1,7.4-0.2,11.1-0.2c3.7,0,7.4-0.1,11.1-0.1l0,0l0,0
					c4.9,0.5,9.8,0.7,14.7,0.7c4.9,0.1,9.8,0,14.7,0c4.9,0,9.8,0,14.7-0.2l14.7-0.2l0.1,0l0,0c3.8,0.5,7.6,0.9,11.5,1.5
					c3.8,0.5,7.6,1.1,11.4,1.7l0,0.2c-3.9-0.1-7.7-0.4-11.5-0.6c-3.8-0.2-7.7-0.5-11.5-0.9l0.1,0c-4.9,0.3-9.8,0.4-14.7,0.4
					c-4.9,0-9.8-0.1-14.7-0.2c-4.9-0.1-9.8-0.5-14.7-0.8c-4.9-0.3-9.8-0.6-14.7-0.8l0.1,0c-3.7,0.3-7.4,0.7-11.1,0.9
					c-3.7,0.3-7.4,0.4-11.1,0.4c-3.7,0-7.4,0-11.1-0.2c-3.7-0.1-7.4-0.4-11.1-0.8L760.9,569.4z"
              />
            </g>
            <g>
              <path
                fill="#FF9A6C"
                d="M1144.6,634.7c1.3-2,2.3-4,3.2-6.2c0.8-2.1,1.5-4.3,2-6.5c0.1-0.6,0.2-1.1,0.3-1.7l0.1-0.8
					c0-0.3,0-0.6,0.1-0.9c0.1-0.6,0.2-1.1,0.2-1.7c0-0.6,0.1-1.1,0.1-1.7l0.1-0.9l0-0.9l0-1.7c0-1.2-0.1-2.3,0-3.5
					c0.4,1.1,0.8,2.3,1,3.4c0.2,1.2,0.5,2.4,0.5,3.6c0.2,2.4,0,4.8-0.5,7.2c-0.5,2.4-1.4,4.7-2.5,6.8
					C1148.1,631.3,1146.6,633.2,1144.6,634.7z"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default ErrorSvg;
