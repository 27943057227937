import Head from "@app/components/Head";
import Error404 from "src/sections/error/404/Error404";

export default function Custom404() {
  return (
    <>
      <Head
        options={{
          title: "Oups, tu as fait sauter l'internet.",
          description:
            "La page que vous recherchez n&apos;est plus là ou n&apos;a jamais existé (dommage). Prenons rendez-vous avec l&apos;un de nos experts ou essayons de rentrer à la maison.",
        }}
      />
      <Error404 />
    </>
  );
}
